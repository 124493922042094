<template>
  <div>
    <div class="d-flex justify-content-end">
      <feather-icon
        v-if="category.id && organizationRequiresEditConfirmation"
        v-b-tooltip.noninteractive.hover.bottom
        :title="$t('Edit')"
        class="cursor-pointer"
        :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
        size="36"
        @click="isEditAble"
      />
    </div>
    <p class="text-center font-weight-bolder font-large-1">
      {{ isUpdate ? $t('Update a Category') : $t('Add a Category') }}
    </p>
    <error-alert
      :fields="fields"
      :error="error"
    />
    <div>
      <validation-observer
        ref="categoryCreateVal"
      >
        <b-row class="py-2">
          <b-col md="6">
            <component
              :is="fields[field].type"
              v-for="field in ['name']"
              :key="field"
              v-model="category[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
          <b-col md="6">
            <label class="font-weight-bolder ml-1">Status</label>
            <component
              :is="fields[field].type"
              v-for="field in ['is_active']"
              :key="field"
              v-model="category[field]"
              md="6"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
        </b-row>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="clear"
              >
                {{ $t('Back to List') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="isCreateForm ? false : !isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </div>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>

<script>

import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BCol, BForm, BRow, VBTooltip,
} from 'bootstrap-vue'
import { isObject, scrollToError } from '@core/utils/utils'
import store from '@/store'
import config from '../CategoriesConfig'

export default {
  name: 'CategoriesForm',
  components: {
    ErrorAlert,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isCreateForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      error: {},
      onSubmit: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    category() {
      return this.$store.state[this.MODULE_NAME].categoryForm
    },
    categoryFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].settingCategoryForm
    },
    isFormChanged() {
      return JSON.stringify(this.category) !== this.categoryFormClone
    },
    isUpdate() {
      return this.category.id
    },
  },
  mounted() {
    if (!this.category.id) this.isEdit = false
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    // keyUpHandler(e) {
    //   console.log(e)
    // },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    submit() {
      this.error = {}
      this.onSubmit = true

      this.$refs.categoryCreateVal.validate().then(success => {
        if (success) {
          const data = this.category
          data.is_active = isObject(data.is_active) ? String(data.is_active.id) : String(data.is_active)
          const category = this.mappingFields(Object.keys(this.fields), data, {
            id: data.id,
          })
          this.sendNotification(this, category, `${this.MODULE_NAME}/${this.isUpdate ? 'update' : 'create'}`)
            .then(() => {
              this.clear()
            }).catch(err => {
              this.error = err.response?.data
            })
        } else {
          scrollToError(this, this.$refs.categoryCreateVal)
        }
      })
    },
    loader() {
      this.$refs.categoryCreateVal.reset()
      const smth = JSON.parse(this.categoryFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },
    clear() {
      this.$refs.categoryCreateVal.reset()
      this.$router.push({ name: 'settings-catalog-categories' })
    },
    getProps(fieldName) {
      return {
        field: this.fields[fieldName],
        name: fieldName,
      }
    },
  },
  setup() {
    const MODULE_NAME = 'settings-catalog-categories'
    const MODULE_NAME_CLONE = 'cloneData'

    const { fields } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      fields,
    }
  },
}
</script>
