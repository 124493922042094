<template>
  <categories-form
    ref="categories-form"
    :is-create-form="true"
  />
</template>

<script>
import CategoriesForm from '@/views/settings/catalog/categories/components/CategoriesForm.vue'

export default {
  name: 'CategoriesCreate',
  components: { CategoriesForm },
  created() {
    const initialData = {
      is_active: true,
    }
    this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, initialData)
    this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_CATEGORY_FORM_CLONE`, initialData)
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['categories-form']) {
      this.$refs['categories-form'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  setup() {
    const MODULE_NAME = 'settings-catalog-categories'
    const MODULE_NAME_CLONE = 'cloneData'

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>
